import type React from 'react';
import { useEffect } from 'react';

type GestureEvent = MouseEvent | TouchEvent;

function useOutsideClick<T extends Element, K extends Element>(
  ref: T | React.MutableRefObject<T | null> | null,
  handler: (event: GestureEvent) => void,
  secondaryRef?: K | React.MutableRefObject<K | null> | null,
  disable?: boolean
): void {
  useEffect(() => {
    if (disable) return;
    const listener = (event: GestureEvent) => {
      if ('button' in event) {
        const mouseEvent = event as MouseEvent;
        if (mouseEvent.button !== 0) {
          return;
        }
      }
      const el = ref && 'current' in ref ? ref.current : ref;
      if (
        !el ||
        el.contains(event.target as Node) ||
        // The click could have caused the element to be removed, such as the
        // "X" in a select list that clears the contents (and thus the X goes
        // away), or an element that appears only on hover. In this case,
        // consider the  click to be _inside_.
        !document.body.contains(event.target as Node)
      ) {
        return;
      }
      if (secondaryRef) {
        const el2 =
          'current' in secondaryRef ? secondaryRef.current : secondaryRef;
        if (
          !el2 ||
          el2.contains(event.target as Node) ||
          // Same as above.
          !document.body.contains(event.target as Node)
        ) {
          return;
        }
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, secondaryRef, disable]);
}

export { useOutsideClick };
